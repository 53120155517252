import React from "react";
import "./BreadCrumb.css";

const BreadCrumb = ({
  breadCrumbText,
  breadCrumbImage,
}) => {
  return (
    <div>
      
      <div className="d-flex align-items-center breadCrumbImg">
        <img
          className="mt-4 text-center"
          //   style={{ height: "400px" }}
          style={{ width: "100%" }}
          src={breadCrumbImage}
          alt="BreadCrumbImage"
        />
        <h2 className="text-white breadCrumbText">{breadCrumbText}</h2>
      </div>

      
    </div>
  );
};

export default BreadCrumb;
