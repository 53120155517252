import React, { useState } from "react";
import logo from "./../../assets/images/logo.svg";
import icon1 from "./../../assets/icon/fe_app-menu.svg";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import "./NavBar.css";
import { NavLink } from "react-router-dom";
// import Navbar from 'react-bootstrap/Navbar'

const NavBar = ({ handleClick, setCheckStatus }) => {
  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg=""
        variant="white"
        fixed="top"
        className="navBG"
      >
        <div className="container">
          <Navbar.Brand href="/" onClick={() => handleClick(0)}>
            <img src={logo} height="60px" alt="logo"></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <img src={icon1} alt="icon"></img>
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto navLink">
              <NavLink
                to="/"
                className={
                  localStorage.getItem("SelectedOption") === "0"
                    ? "activeNav nav-link navRes"
                    : "inactiveNav nav-link navRes"
                }
                onClick={() => handleClick(0)}
              >
                <span className="navH">HOME</span>
              </NavLink>
              <NavLink
                to="/rooms-tarrif"
                className={
                  localStorage.getItem("SelectedOption") === "1"
                    ? "activeNav nav-link navRes"
                    : "inactiveNav nav-link navRes"
                }
                onClick={() => {
                  handleClick(1);
                  setCheckStatus(false);
                }}
              >
                <span className="navH">ROOMS &amp; SUITES</span>
              </NavLink>
              {/* <NavLink to="/reservation"  className={ localStorage.getItem( 'SelectedOption' ) === '2' ? 'activeNav nav-link navRes' : 'inactiveNav nav-link navRes'}  onClick={() =>handleClick(2)}>
                <span className="navH">RESERVATION</span>
                </NavLink> */}
              <NavLink
                to="/facilities"
                className={
                  localStorage.getItem("SelectedOption") === "2"
                    ? "activeNav nav-link navRes"
                    : "inactiveNav nav-link navRes"
                }
                onClick={() => handleClick(2)}
              >
                <span className="navH">FACILITIES</span>
              </NavLink>
              {/* <NavLink to="/events"  className={selected === 4 ? 'activeNav nav-link navRes' : 'inactiveNav nav-link navRes'}  onClick={() =>handleClick(4)}>
                <span className="navH">EVENTS</span>
                </NavLink> */}
              <NavLink
                to="/restaurant"
                className={
                  localStorage.getItem("SelectedOption") === "3"
                    ? "activeNav nav-link navRes"
                    : "inactiveNav nav-link navRes"
                }
                onClick={() => handleClick(3)}
              >
                <span className="navH">RESTAURANT</span>
              </NavLink>
              <NavLink
                to="/gallery"
                className={
                  localStorage.getItem("SelectedOption") === "4"
                    ? "activeNav nav-link navRes"
                    : "inactiveNav nav-link navRes"
                }
                onClick={() => handleClick(4)}
              >
                <span className="navH">GALLERY</span>
              </NavLink>
            </Nav>
            {/* <Nav>
              <button className="navBtn">SIGN IN</button>
            </Nav> */}
          </Navbar.Collapse>
        </div>
      </Navbar>
    </div>
  );
};

export default NavBar;
