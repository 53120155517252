import React from "react";
import "./ContentSection.css";

const ContentSection = ({
  contentTitle,
  contentBody,
  contentImage,
  imgPosition,
  discoverRooms,
  discoverRestaurantHandler,
}) => {
  return (
    <div className="contentSection">
      <div className="container">
        <div className="row">
          {imgPosition === "left" && (
            <>
              <div className="col-md-6 d-flex flex-column justify-content-center mb-5">
                <h2 className="pb-3">{contentTitle}</h2>
                <p className="text-justify pb-3">{contentBody}</p>
                <div>
                  <button
                    onClick={discoverRooms}
                    className="commonBtn1"
                    style={{ fontSize: "15px" }}
                  >
                    Discover more
                  </button>
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center mb-5">
                <img
                  className="d-block w-100"
                  src={contentImage}
                  alt="Content"
                />
              </div>
            </>
          )}

          {imgPosition === "right" && (
            <>
              <div className="col-md-6 d-flex flex-column justify-content-center mb-5">
                <img
                  className="d-block w-100"
                  src={contentImage}
                  alt="Content"
                />
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center mb-5">
                <h2 className="pb-3">{contentTitle}</h2>
                <p className="text-justify pb-3">{contentBody}</p>
                <div>
                  <button
                    onClick={discoverRestaurantHandler}
                    className="commonBtn1"
                    style={{ fontSize: "15px" }}
                  >
                    Discover more
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentSection;
