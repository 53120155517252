import React, { useEffect } from "react";
import "./Restaurant.css";

import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import { useState } from "react";
import breadCrumbImageI from "./../../assets/images/restaurantPage.png";
import axiosInstance from "../../services/axiosInstance";
import CheckAvailBox from "../../components/CheckAvailBox/CheckAvailBox";
import restaurantImage1 from "./../../assets/images/restaurantImage1.png";
import restaurantImage2 from "./../../assets/images/restaurantImage2.png";
import restaurantImage3 from "./../../assets/images/restaurantImage3.png";
import restaurantImage4 from "./../../assets/images/restaurantImage4.png";
import restaurantImage5 from "./../../assets/images/restaurantImage5.png";
import restaurantImage6 from "./../../assets/images/restaurantImage6.png";
import restaurantImage7 from "./../../assets/images/restaurantImage7.png";

const Restaurant = () => {
  const [breadCrumbTextV, setbreadCrumbTextV] = useState("");
  const [breadCrumbImageV, setbreadCrumbImageV] = useState("");
  const [banners, setBanners] = useState();

  useEffect(() => {
    axiosInstance
      .get("/api/banner")
      .then((res) => {
        setBanners(res?.data?.banners);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setbreadCrumbTextV("Restaurant & Lounge  ");

    {
      banners &&
        banners.length > 0 &&
        banners.map(
          (banner, index) =>
            banner.page === "restaurant" &&
            banner.status === "active" &&
            setbreadCrumbImageV(banner.image)
        );
    }
  });

  return (
    <div>
      <BreadCrumb
        breadCrumbText={breadCrumbTextV}
        breadCrumbImage={breadCrumbImageV}
      />

      <div className="bgPattern mt--200">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt---100 mb---100 text-center">
              {/* <h2 className="pb-3">Services We Offer </h2> */}
              {/* <p className="pb-3">Ideally suited for holding multi-faceted events, either</p> */}
            </div>
          </div>
        </div>
      </div>

      <div className="roomSection mt--100">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex flex-column justify-content-center">
              <img
                className="d-block w-100"
                src={restaurantImage1}
                alt="restaurantImage1"
              />
            </div>

            <div className="col-md-6 d-flex flex-column justify-content-center">
              <div className="p-5">
                <h6
                  className="text-uppercase pb-3"
                  style={{ letterSpacing: "10px" }}
                >
                  Relaxed Atmosphere
                </h6>

                <h2>
                  Finest Luxary <br /> Restaurant
                </h2>
                <p className="text-justify pb-3 pt-3">
                  Enjoy excellent Service, a relaxing ambiance and outstanding
                  facilities at one of the finest hotels in Sylhet. The guest
                  rooms are elegant and spacious, appeling to both business and
                  leisure travellers.
                </p>
                <h6>24 hours open for room guests.</h6>
                <h6>Outside guest : 12 PM - 11 PM</h6>
                <h6 className="text-uppercase pt-2 pb-2">
                  We Offer : Thai Food | Chinees Food
                </h6>

                <button className="commonBtn1" style={{ fontSize: "15px" }}>
                  Tap for Menu
                </button>
              </div>
            </div>

            <div className="col-md-12 p-5 m-5">
              <h2 className="text-center">Have a look that food we offer</h2>
            </div>

            <div className="col-md-6 d-flex flex-column justify-content-center">
              <div className="p-5 m-3">
                <div className="row">
                  <div className="col-md-12 col-sm-12 d-flex align-items-center">
                    <h4 className="float-left mt-2">
                      Complementary Breakfast &nbsp;&nbsp;
                    </h4>
                    <div className="hbBord w-25 border border-dark float-left"></div>
                  </div>
                  {/* <div className="col-md-6 d-flex align-items-center"></div> */}
                </div>
                <p className="text-justify pb-3 pt-3">
                  We always go the extra mile in providing excellent service and
                  quality for our guests. Therefore, we offer a delicious
                  breakfast buffet made from fresh ingredients to guarantee the
                  good taste and the best start to the day! Join us for
                  breakfast and enjoy an unlimited selection of home-baked
                  pastries, continental charcuterie, vegetables, yoghurt,
                  grills, eggs, juices and fruits, as well as various hot
                  drinks.
                </p>
              </div>
            </div>

            <div className="col-md-6 d-flex flex-column justify-content-center">
              <img
                className="d-block w-100"
                src={restaurantImage2}
                alt="restaurantImage2"
              />
            </div>

            <div className="col-md-12 p-5">
              <h2 className="text-center"></h2>
            </div>

            <div className="col-md-6 d-flex flex-column justify-content-center">
              <img
                className="d-block w-100"
                src={restaurantImage3}
                alt="restaurantImage3"
              />
            </div>

            <div className="col-md-6 d-flex flex-column justify-content-center">
              <div className="p-5 m-3">
                <div className="row">
                  <div className="col-md-12 col-sm-12 d-flex align-items-center">
                    <h4 className="float-left mt-2">
                      Lunch / Dinner Dishes &nbsp;&nbsp;
                    </h4>
                    <div className="hbBord w-25 border border-dark float-left"></div>
                  </div>
                  {/* <div className="col-md-6 d-flex align-items-center"></div> */}
                </div>
                <p className="text-justify pb-3 pt-3">
                  We always go the extra mile in providing excellent service and
                  quality for our guests. Therefore, we offer a delicious
                  breakfast buffet made from fresh ingredients to guarantee the
                  good taste and the best start to the day! Join us for
                  breakfast and enjoy an unlimited selection of home-baked
                  pastries, continental charcuterie, vegetables, yoghurt,
                  grills, eggs, juices and fruits, as well as various hot
                  drinks.
                </p>
              </div>
            </div>

            <div className="col-md-12 p-5 mt-5">
              <h2 className="text-center">We Arrange</h2>
              <p className="text-center">
                Our Event planner can arrange many types of celebrations
              </p>
            </div>

            <div className="col-md-12 roomCardBoxs">
              <div className="container">
                {/* <h2 className="text-uppercase text-center d-block pb-3"> OUR ROOMS &amp; SUITES </h2> */}
                {/* <div className="text-right mt-4 mb-4">
            <button className="commonBtn1" style={{ fontSize: "15px" }}>
              View All <img src={icon2} alt="" className="commonIcon1" />
            </button>
          </div> */}

                <div className="card-deck">
                  <div className="card text-center border-0 p-0 shadow-none">
                    <img
                      className="text-center"
                      // style={{ height: "75px" }}
                      src={restaurantImage4}
                      alt="icon"
                    />
                    <div className="card-footers text-white">
                      <h2>Wedding Party</h2>
                      <p>Accommodate a range of business events</p>
                    </div>
                  </div>
                  <div className="card text-center border-0 p-0 shadow-none">
                    <img
                      className="text-center"
                      // style={{ height: "75px" }}
                      src={restaurantImage5}
                      alt="icon"
                    />
                    <div className="card-footers text-white">
                      <h2>Catering Service</h2>
                      <p>Accommodate a range of business events</p>
                    </div>
                  </div>
                  <div className="card text-center border-0 p-0 shadow-none">
                    <img
                      className="text-center"
                      // style={{ height: "75px" }}
                      src={restaurantImage6}
                      alt="icon"
                    />
                    <div className="card-footers text-white">
                      <h2>Takeaway</h2>
                      <p>Accommodate a range of business events</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 pt-5">
              <h2 className="text-center p-5">Birthday Parties</h2>
              <img
                className="d-block w-100"
                src={restaurantImage7}
                alt="restaurantImage7"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Restaurant;
