import { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./HomeSlider.css";
import axiosInstance from "../../services/axiosInstance";
import slider1 from "./../../assets/images/slider1.png";
import slider2 from "./../../assets/images/slider2.png";
import slider3 from "./../../assets/images/slider3.png";
import icon2 from "./../../assets/icon/right-arrow.svg";

const HomeSlider = ({ discoverFacilities, goToGallery }) => {
  const [banners, setBanners] = useState();

  useEffect(() => {
    axiosInstance
      .get("/api/banner")
      .then((res) => {
        setBanners(res?.data?.banners);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(banners);

  return (
    <div className="homeSlides">
      <Carousel fade style={{ position: "relative" }}>
        {banners &&
          banners.length > 0 &&
          banners.map(
            (banner, index) =>
              banner.page === "home" &&
              banner.status === "active" && (
                <Carousel.Item interval={5000}>
                  <div className="overlay"></div>
                  <img
                    className="d-block w-100"
                    src={banner.image}
                    alt="First slide"
                    // height="550px"
                    // width="1440px"
                  />
                  {/* <div className="slideElem-container">
                    { <div className="slideElem">
                      <h2 className="slideH3">
                        A place for relax &amp; freshness
                      </h2>
                      <button onClick={goToGallery} className="commonBtn1">
                        TAKE A TOUR{" "}
                        <img src={icon2} alt="" className="commonIcon1" />
                      </button>
                      <div className="divider" />
                      <button
                        onClick={discoverFacilities}
                        className="commonBtn2"
                      >
                        LEARN MORE
                      </button>
                    </div> }
                  </div>{" "} */}
                </Carousel.Item>
              )
          )}
      </Carousel>
    </div>
  );
};

export default HomeSlider;
