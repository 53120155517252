const CarouselIndicators = ({ slides, currentIndex, switchIndex }) => {
  return (
    <div className="carouselS-indicators">
      {slides && slides.length > 0 && slides?.map((_, index) => (
        <button key={index}
          className={`carouselS-indicator-item${
            currentIndex === index ? " active" : ""
          }`}
          onClick={() => switchIndex(index)}
        ></button>
      ))}
    </div>
  );
};

export default CarouselIndicators;
