import React from "react";
import logo from "./../../assets/images/logo.svg";
import gps from "./../../assets/icon/gps.svg";
import call from "./../../assets/icon/call.svg";
import mail from "./../../assets/icon/mail.svg";

const Footer = () => {
  return (
    <div>
      <footer className="text-center text-lg-start text-white mainBG">
        <section className="p-4">
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3 d-flex align-items-center w100">
              <div className="col-lg-2 col-md-2 col-sm-12 col-12 mx-auto mb-4 dFlex jCC">
                <div className="bg-white rounded-circle logoCircle">
                  <img src={logo} width="100px" alt="logo" />
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-12 col-12 mx-auto mb-4 dFlex jCC">
                <div className="float-left d-flex align-items-center gap-1 text-left">
                  <img
                    className="float-left"
                    style={{ height: "32px" }}
                    src={gps}
                    alt="icon"
                  />
                  <a href="#!" className="text-reset">
                    10 &amp; 11 th Floor Al-Hamra Shopping City, Hazrat
                    Shahjalal Road, Zindabazar, Sylhet -3100.
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-12 col-12 mx-auto mb-4 dFlex jCC">
                <div className="float-left d-flex align-items-center gap-1 text-left">
                  <img
                    className="float-left"
                    style={{ height: "32px" }}
                    src={call}
                    alt="icon"
                  />
                  <a href="#!" className="text-reset">
                    +880172509598, +880821719076
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-12 col-12 mx-auto mb-4 dFlex jCC">
                <div className="float-left d-flex align-items-center gap-1 text-left">
                  <img
                    className="float-left"
                    style={{ height: "32px" }}
                    src={mail}
                    alt="icon"
                  />
                  <a href="#!" className="text-reset">
                    richmondhotel.rha@gmail.com
                  </a>
                </div>
              </div>



            </div>
          </div>
        </section>
        <div className="text-center p-4 border-top">
          Copyright © 2021 by{" "}
          <a className="text-reset fw-bold" href="https://sdl-tech.com/">
            SDL TECH
          </a>
          . All Rights Reserved
        </div>
      </footer>
    </div>
  );
};

export default Footer;
