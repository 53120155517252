import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./CheckAvailBox.css";

import icon4 from "./../../assets/icon/calender-time.svg";

const CheckAvailBox = ({
  setCheckStatus,
  page,
  setCheckStatusFromRoom,
  handleClick,
}) => {
  const navigate = useNavigate();

  let defaultDate = new Date().toLocaleDateString("en-CA");
  const [checkInDate, setCheckInDate] = useState(
    localStorage.getItem("checkIn") === null
      ? defaultDate
      : localStorage.getItem("checkIn")
  );
  const [checkOutDate, setCheckOutDate] = useState(
    localStorage.getItem("checkOut") === null
      ? defaultDate
      : localStorage.getItem("checkOut")
  );
  const [guestNum, setGuestNum] = useState(
    localStorage.getItem("guestNum") === null
      ? 0
      : localStorage.getItem("guestNum")
  );

  const checkAvailabilityHandler = (checkIn, checkOut, guestNum) => {
    localStorage.setItem("checkIn", checkIn);
    localStorage.setItem("checkOut", checkOut);
    localStorage.setItem("guestNum", guestNum);
    if (page === "home") handleClick(1);

    setCheckStatus(true);
    if (page === "room&terrif") setCheckStatusFromRoom(true);

    let obj = {
      checkIn,
      checkOut,
      guestNum,
    };

    navigate("/rooms-tarrif", { state: obj });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8 col-sm-12 col-12 checkAvailBox">
            <div className="row row-cols-auto">
              <div className="col-md-3 mb-2 dFlex">
                <div className="d-flex align-items-center pb-2 w100">
                  <img src={icon4} alt="icon" className="commonIcon2" />
                  <span className="text-uppercase">Check - in</span>
                </div>
                <input
                  type="date"
                  className="form-control form-control-sm"
                  style={{ fontSize: "12px" }}
                  value={checkInDate}
                  onChange={(e) => setCheckInDate(e.target.value)}
                />
              </div>

              <div className="col-md-3 mb-2 dFlex">
                <div className="d-flex align-items-center pb-2 w100">
                  <img src={icon4} alt="icon" className="commonIcon2" />
                  <span className="text-uppercase">Check - Out</span>
                </div>
                <input
                  type="date"
                  className="form-control form-control-sm"
                  style={{ fontSize: "12px" }}
                  value={checkOutDate}
                  onChange={(e) => setCheckOutDate(e.target.value)}
                />
              </div>

              <div className="col-md-2 mb-2 dFlex">
                <div className="d-flex align-items-center pb-2 w100">
                  <img src={icon4} alt="icon" className="commonIcon2" />
                  <span className="text-uppercase">GUEST</span>
                </div>
                <input
                  type="number"
                  className="form-control form-control-sm"
                  style={{ fontSize: "12px" }}
                  min="1"
                  max="100"
                  placeholder="0"
                  value={guestNum}
                  onChange={(e) => setGuestNum(e.target.value)}
                />
              </div>

              <div className="col-md-4 checkAvailBtn d-flex justify-content-center">
                <button
                  onClick={() =>
                    checkAvailabilityHandler(
                      checkInDate,
                      checkOutDate,
                      guestNum
                    )
                  }
                  className="commonBtn1"
                >
                  Check Availability
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </div>
  );
};

export default CheckAvailBox;
