import React, { useEffect } from "react";
import "./Gallery.css";
import axiosInstance from "../../services/axiosInstance";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import { useState } from "react";
import breadCrumbImageI from "./../../assets/images/restaurantPage.png";
import gallery01 from "./../../assets/images/IMG-20220510-WA0021.jpg";
import gallery02 from "./../../assets/images/IMG-20220510-WA0027.jpg";
import gallery03 from "./../../assets/images/IMG-20220510-WA0030.jpg";
import gallery04 from "./../../assets/images/IMG-20220510-WA0029.jpg";
import gallery05 from "./../../assets/images/IMG-20220510-WA0028.jpg";
import gallery06 from "./../../assets/images/IMG-20220510-WA0026.jpg";
import gallery07 from "./../../assets/images/IMG-20220510-WA0022.jpg";
import gallery08 from "./../../assets/images/gallery08.png";
import gallery09 from "./../../assets/images/gallery09.png";
import gallery10 from "./../../assets/images/gallery10.png";
import gallery11 from "./../../assets/images/gallery11.png";
import gallery12 from "./../../assets/images/gallery12.png";
import gallery13 from "./../../assets/images/gallery13.png";
import gallery14 from "./../../assets/images/gallery14.png";
import gallery15 from "./../../assets/images/gallery15.png";
import gallery16 from "./../../assets/images/gallery16.png";
import gallery17 from "./../../assets/images/gallery17.png";
import gallery18 from "./../../assets/images/gallery18.png";
import gallery19 from "./../../assets/images/gallery19.png";
import FsLightbox from "fslightbox-react";

const Gallery = () => {
  const [breadCrumbTextV, setbreadCrumbTextV] = useState("");
  const [breadCrumbImageV, setbreadCrumbImageV] = useState("");
  const [banners, setBanners] = useState();

  useEffect(() => {
    axiosInstance
      .get("/api/banner")
      .then((res) => {
        setBanners(res?.data?.banners);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setbreadCrumbTextV("Our Gallery");
    {
      banners &&
        banners.length > 0 &&
        banners.map(
          (banner, index) =>
            banner.page === "gallery" &&
            banner.status === "active" &&
            setbreadCrumbImageV(banner.image)
        );
    }
  });

  // FlightBox Click Function
  const [toggler, setToggler] = useState(false);
  const [togglerGuestRooms, setTogglerGuestRooms] = useState(false);
  const [togglerService, setTogglerService] = useState(false);
  const [togglerDinning, setTogglerDinning] = useState(false);

  return (
    <div>
      <BreadCrumb
        breadCrumbText={breadCrumbTextV}
        breadCrumbImage={breadCrumbImageV}
      />

      <div className="bgPattern mt--200">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt---100 mb---100 text-center">
              <h2 className="pb-3">See Our Beautiful Gallery </h2>
              {/* <p className="pb-3">Ideally suited for holding multi-faceted events, either</p> */}
            </div>
          </div>
        </div>
      </div>

      <div className="roomSection mt--100">
        <div className="container">
          <div className="row row-cols-auto">
            <div className="col-md-12 p-5 text-center">
              <h2 className="text-center">Hotel Views</h2>

              <img
                className="car img-fluid m-2"
                src={gallery01}
                alt="a"
                onClick={() => setToggler(!toggler)}
              />
              <img
                className="car img-fluid m-2"
                src={gallery02}
                alt="a"
                onClick={() => setToggler(!toggler)}
              />
              <img
                className="car img-fluid m-2"
                src={gallery03}
                alt="a"
                onClick={() => setToggler(!toggler)}
              />
              <img
                className="car img-fluid m-2"
                src={gallery04}
                alt="a"
                onClick={() => setToggler(!toggler)}
              />
              <img
                className="car img-fluid m-2"
                src={gallery05}
                alt="a"
                onClick={() => setToggler(!toggler)}
              />
            </div>

            <div className="col-md-12 p-5 text-center">
              <h2 className="text-center">Guest Rooms</h2>

              <img
                className="car img-fluid m-2"
                src={gallery06}
                alt="a"
                onClick={() => setTogglerGuestRooms(!togglerGuestRooms)}
              />
              <img
                className="car img-fluid m-2"
                src={gallery07}
                alt="a"
                onClick={() => setTogglerGuestRooms(!togglerGuestRooms)}
              />
              <img
                className="car img-fluid m-2"
                src={gallery08}
                alt="a"
                onClick={() => setTogglerGuestRooms(!togglerGuestRooms)}
              />
              <img
                className="car img-fluid m-2"
                src={gallery09}
                alt="a"
                onClick={() => setTogglerGuestRooms(!togglerGuestRooms)}
              />
              <img
                className="car img-fluid m-2"
                src={gallery10}
                alt="a"
                onClick={() => setTogglerGuestRooms(!togglerGuestRooms)}
              />
            </div>

            <div className="col-md-12 p-5 text-center">
              <h2 className="text-center">Services</h2>

              <img
                className="car img-fluid m-2"
                src={gallery11}
                alt="a"
                onClick={() => setTogglerService(!togglerService)}
              />
              <img
                className="car img-fluid m-2"
                src={gallery12}
                alt="a"
                onClick={() => setTogglerService(!togglerService)}
              />
              <img
                className="car img-fluid m-2"
                src={gallery13}
                alt="a"
                onClick={() => setTogglerService(!togglerService)}
              />
            </div>

            <div className="col-md-12 p-5 text-center">
              <h2 className="text-center">Dinning</h2>

              <img
                className="car img-fluid m-2"
                src={gallery14}
                alt="a"
                onClick={() => setTogglerDinning(!togglerDinning)}
              />
              <img
                className="car img-fluid m-2"
                src={gallery15}
                alt="a"
                onClick={() => setTogglerDinning(!togglerDinning)}
              />
              <img
                className="car img-fluid m-2"
                src={gallery16}
                alt="a"
                onClick={() => setTogglerDinning(!togglerDinning)}
              />
              <img
                className="car img-fluid m-2"
                src={gallery17}
                alt="a"
                onClick={() => setTogglerDinning(!togglerDinning)}
              />
              <img
                className="car img-fluid m-2"
                src={gallery18}
                alt="a"
                onClick={() => setTogglerDinning(!togglerDinning)}
              />
              <img
                className="car img-fluid m-2"
                src={gallery19}
                alt="a"
                onClick={() => setTogglerDinning(!togglerDinning)}
              />
            </div>

            <FsLightbox
              toggler={toggler}
              sources={[gallery01, gallery02, gallery03, gallery04, gallery05]}
            />

            <FsLightbox
              toggler={togglerGuestRooms}
              sources={[gallery06, gallery07, gallery08, gallery09, gallery10]}
            />

            <FsLightbox
              toggler={togglerService}
              sources={[gallery11, gallery12, gallery13]}
            />

            <FsLightbox
              toggler={togglerDinning}
              sources={[
                gallery14,
                gallery15,
                gallery16,
                gallery17,
                gallery18,
                gallery19,
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
