import React, { useEffect } from "react";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import HomeSlider from "./../../components/HomeSlider/HomeSlider";
import CheckAvailBox from "./../../components/CheckAvailBox/CheckAvailBox";
import ContentSection from "./../../components/ContentSection/ContentSection";
import { useState } from "react";
import icon5 from "./../../assets/icon/cozy-room2.svg";
import icon6 from "./../../assets/icon/ac-service2.svg";
import icon7 from "./../../assets/icon/free-wifi2.svg";
import icon8 from "./../../assets/icon/special-offer2.svg";
import room1 from "./../../assets/images/room1.png";
import room2 from "./../../assets/images/room2.png";
import room3 from "./../../assets/images/room3.png";
import mug from "./../../assets/icon/mug.svg";
import wifi from "./../../assets/icon/wifi.svg";
import monitor from "./../../assets/icon/monitor.svg";
import contentImageTop from "./../../assets/images/content01.png";
import contentSecondImage from "./../../assets/images/content02.png";
import event from "./../../assets/images/event.png";

import Carousel from "react-bootstrap/Carousel";
import "./../../components/HomeSlider/HomeSlider.css";
import slider1 from "./../../assets/images/slider1.png";
import slider2 from "./../../assets/images/slider2.png";
import slider3 from "./../../assets/images/slider3.png";
import icon2 from "./../../assets/icon/right-arrow.svg";

import star from "./../../assets/icon/star.svg";

import tsazzad from "./../../assets/images/tsazzad.png";
import tmirza from "./../../assets/images/tmirza.png";
import tnizam from "./../../assets/images/tnizam.png";

const Home = ({ checkStatus, setCheckStatus, handleClick }) => {
  const [contentTopBody, setContentTopBody] = useState("");
  const [contentTopTitle, setContentTopTitle] = useState("");
  const [contentSecondBody, setContentSecondBody] = useState("");
  const [contentSecondTitle, setContentSecondTitle] = useState("");
  const [contentTopImage, setContentTopImage] = useState("");
  const [contentImageSecond, setContentImageSecond] = useState("");

  useEffect(() => {
    setContentTopTitle("Welcome to Richmond");
    setContentTopBody(
      "Enjoy excellent Service, a relaxing ambiance and outstanding facilities at one of the finest hotels in Sylhet. The guest rooms are elegant and spacious, appeling to both business and leisure travellers. IN-room amenties include: satellite television, broadband internet connection, Personal kitchen facilities with dining and living room."
    );
    setContentTopImage(contentTopImage);

    setContentSecondTitle("Restaurent & Lounge");
    setContentSecondBody(
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis adipiscing in dictumst blandit fusce sem. Amet, justo, proin phasellus tristique convallis a, sed ut. Morbi imperdiet morbi in maecenas nibh egestas elit ultrices faucibus. Augue elit quisque mi turpis. Porttitor a aliquet cursus nec convallis sagittis interdum hac dolor. Tortor, euismod porta purus iaculis faucibus. Cras odio odio et, etiam dictum cursus senectus tincidunt tellus. Sed erat habitant vivamus ullamcorper eu at. Viverra malesuada quis ut odio quis. Mi cursus dolor in nisl. Massa, tincidunt rhoncus semper cursus. Nisl eu mi quisque ornare bibendum."
    );
    setContentImageSecond(contentSecondImage);
  });

  const navigate = useNavigate();

  const discoverRooms = () => {
    handleClick(1);
    navigate("/rooms-tarrif");
  };
  const discoverRestaurantHandler = () => {
    handleClick(3);
    navigate("/restaurant");
  };

  const discoverFacilities = () => {
    handleClick(2);
    navigate("/facilities");
  };

  const goToGallery = () => {
    handleClick(4);
    navigate("/gallery");
  };

  return (
    <div>
      <HomeSlider
        discoverFacilities={discoverFacilities}
        goToGallery={goToGallery}
      />
      {/* <CheckAvailBox
        checkStatus={checkStatus}
        setCheckStatus={setCheckStatus}
        page="home"
      /> */}
      <div className="mt---100"></div>
      <ContentSection
        contentTitle={contentTopTitle}
        contentBody={contentTopBody}
        contentImage={contentImageTop}
        discoverRooms={discoverRooms}
        imgPosition="left"
      />

      <div className="serviceCardBox">
        <div className="container">
          <h2 className="text-uppercase text-center d-block pb-3">
            OUR CORE PRIORITY
          </h2>
          <div className="card-deck">
            <div className="card text-center border-0">
              <img
                className="mt-4 text-center"
                style={{ height: "75px" }}
                src={icon5}
                alt="icon"
              />
              <div className="card-body">
                <h2 className="card-title">Cozy Room</h2>
                <p className="card-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  ullamcorper est et massa pretium.
                </p>
              </div>
            </div>
            <div className="card text-center border-0">
              <img
                className="mt-4 text-center"
                style={{ height: "75px" }}
                src={icon6}
                alt="icon"
              />
              <div className="card-body">
                <h2 className="card-title">Ac Service</h2>
                <p className="card-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  ullamcorper est et massa pretium.
                </p>
              </div>
            </div>
            <div className="card text-center border-0">
              <img
                className="mt-4 text-center"
                style={{ height: "75px" }}
                src={icon7}
                alt="icon"
              />
              <div className="card-body">
                <h2 className="card-title">Free Wifi</h2>
                <p className="card-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  ullamcorper est et massa pretium.
                </p>
              </div>
            </div>
            <div className="card text-center border-0">
              <img
                className="mt-4 text-center"
                style={{ height: "75px" }}
                src={icon8}
                alt="icon"
              />
              <div className="card-body">
                <h2 className="card-title">Special Offer</h2>
                <p className="card-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  ullamcorper est et massa pretium.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContentSection
        contentTitle={contentSecondTitle}
        contentBody={contentSecondBody}
        contentImage={contentImageSecond}
        discoverRestaurantHandler={discoverRestaurantHandler}
        imgPosition="right"
      />

      <div className="roomCardBox">
        <div className="container">
          <h2 className="text-uppercase text-center d-block pb-3">
            OUR ROOMS &amp; SUITES
          </h2>
          {/* <div className="text-right mt-4 mb-4">
            <button className="commonBtn1" style={{ fontSize: "15px" }}>
              View All <img src={icon2} alt="" className="commonIcon1" />
            </button>
          </div> */}

          <div className="card-deck">
            <div className="card text-center border-0">
              <img
                className="text-center"
                // style={{ height: "75px" }}
                src={room1}
                alt="icon"
              />
              <div className="roomIcon text-right">
                <img
                  className="text-center mr-2"
                  style={{ height: "40px" }}
                  src={mug}
                  alt="icon"
                />
                <img
                  className="text-center mr-2"
                  style={{ height: "40px" }}
                  src={wifi}
                  alt="icon"
                />
                <img
                  className="text-center"
                  style={{ height: "40px" }}
                  src={monitor}
                  alt="icon"
                />
              </div>
              <div className="card-footer">
                <h2>Single Room</h2>
              </div>
              <div className="card-body">
                <p className="card-text text-justify pb-3">
                  Lorem ipsum dolor sit ame ullamcorper est et massa pretium,
                  Lorem ipsum dolor sit ame ullamcorper est et massa pretium
                  <a href="#"> [...See More]</a>
                </p>
                <div>
                  <h3 className="float-left">80$</h3>
                  <p className="float-left mt-2">/Day</p>
                </div>

                <div className="text-right">
                  <button className="commonBtn1" style={{ fontSize: "15px" }}>
                    BOOK NOW
                  </button>
                </div>
              </div>
            </div>
            <div className="card text-center border-0">
              <img
                className="text-center"
                // style={{ height: "75px" }}
                src={room2}
                alt="icon"
              />
              <div className="roomIcon text-right">
                <img
                  className="text-center mr-2"
                  style={{ height: "40px" }}
                  src={mug}
                  alt="icon"
                />
                <img
                  className="text-center mr-2"
                  style={{ height: "40px" }}
                  src={wifi}
                  alt="icon"
                />
                <img
                  className="text-center"
                  style={{ height: "40px" }}
                  src={monitor}
                  alt="icon"
                />
              </div>
              <div className="card-footer">
                <h2>Double Room</h2>
              </div>
              <div className="card-body">
                <p className="card-text text-justify pb-3">
                  Lorem ipsum dolor sit ame ullamcorper est et massa pretium,
                  Lorem ipsum dolor sit ame ullamcorper est et massa pretium
                  <a href="#"> [...See More]</a>
                </p>
                <div>
                  <h3 className="float-left">80$</h3>
                  <p className="float-left mt-2">/Day</p>
                </div>

                <div className="text-right">
                  <button className="commonBtn1" style={{ fontSize: "15px" }}>
                    BOOK NOW
                  </button>
                </div>
              </div>
            </div>
            <div className="card text-center border-0">
              <img
                className="text-center"
                // style={{ height: "75px" }}
                src={room3}
                alt="icon"
              />
              <div className="roomIcon text-right">
                <img
                  className="text-center mr-2"
                  style={{ height: "40px" }}
                  src={mug}
                  alt="icon"
                />
                <img
                  className="text-center mr-2"
                  style={{ height: "40px" }}
                  src={wifi}
                  alt="icon"
                />
                <img
                  className="text-center"
                  style={{ height: "40px" }}
                  src={monitor}
                  alt="icon"
                />
              </div>
              <div className="card-footer">
                <h2>Delux Room</h2>
              </div>
              <div className="card-body">
                <p className="card-text text-justify pb-3">
                  Lorem ipsum dolor sit ame ullamcorper est et massa pretium,
                  Lorem ipsum dolor sit ame ullamcorper est et massa pretium
                  <a href="#"> [...See More]</a>
                </p>
                <div>
                  <h3 className="float-left">80$</h3>
                  <p className="float-left mt-2">/Day</p>
                </div>

                <div className="text-right">
                  <button className="commonBtn1" style={{ fontSize: "15px" }}>
                    BOOK NOW
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contentSection">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex flex-column justify-content-center mb-5">
              <h2 className="pb-3">Event Planning </h2>
              <p className="text-justify pb-3 font-weight-bold">
                Our Event planer can arrange many types of celebrations :
              </p>
              <ul className="pl-4">
                <li>Akdhs, Wedding, Walimas, Anniversaries &amp; Birthdays</li>
                <li>School, College &amp; University Reunions</li>
                <li>Iftari &amp; Eid celebrations</li>
                <li>Business Conferrence &amp; office Functions </li>
                <li>Take Away </li>
                <li>Catering Services </li>
              </ul>
              <div>
                <button
                  onClick={discoverFacilities}
                  className="commonBtn1"
                  style={{ fontSize: "15px" }}
                >
                  Discover more
                </button>
              </div>
            </div>
            <div className="col-md-6 d-flex flex-column justify-content-center mb-5">
              <img className="d-block w-100" src={event} alt="Content" />
            </div>
          </div>
        </div>
      </div>

      <header>
        {/* <!-- This div is  intentionally blank. It creates the transparent black overlay over the video which you can modify in the CSS --> */}
        <div className="overlay"></div>

        {/* <!-- The HTML5 video element that will create the background video on the header --> */}
        <video
          playsInline="playsinline"
          autoPlay="autoplay"
          muted="muted"
          loop="loop"
        >
          <source
            src="https://storage.googleapis.com/coverr-main/mp4/Mt_Baker.mp4"
            type="video/mp4"
          />
        </video>

        {/* <!-- The header content --> */}
        <div className="container h-100">
          <div className="d-flex h-100 text-center align-items-center">
            <div className="w-100 text-white">
              <p className="lead mb-0 font-weight-bold">RELAX AND ENJOY YOUR</p>
              <h1 className="display-3 font-weight-bold">HOLIDAY</h1>
            </div>
          </div>
        </div>
      </header>

      <div className="contentSection mt-5">
        <div className="container">
          <h2 className="text-uppercase text-center d-block pb-3">GALLERY</h2>
          <div className="text-right mt-4 mb-4">
            <button
              onClick={goToGallery}
              className="commonBtn1"
              style={{ fontSize: "15px" }}
            >
              View All <img src={icon2} alt="" className="commonIcon1" />
            </button>
          </div>
          <div style={{ paddingTop: "0px" }}>
            <Carousel fade style={{ position: "relative" }}>
              <Carousel.Item interval={5000}>
                <div className="overlay"></div>
                <img
                  className="d-block w-100"
                  src={slider1}
                  alt="First slide"
                />
              </Carousel.Item>

              <Carousel.Item interval={5000}>
                <div className="overlay"></div>
                <img
                  className="d-block w-100"
                  src={slider2}
                  alt="Second slide"
                />
              </Carousel.Item>

              <Carousel.Item interval={5000}>
                <div className="overlay"></div>
                <img
                  className="d-block w-100"
                  src={slider3}
                  alt="Third slide"
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>

      <div className="roomCardBox">
        <div className="container">
          <h2 className="text-uppercase text-center d-block pb-3">
            OUR BELOVED CUSTOMER SAY
          </h2>
          <div className="text-right mt-4 mb-4">
            <button className="commonBtn1" style={{ fontSize: "15px" }}>
              View All <img src={icon2} alt="" className="commonIcon1" />
            </button>
          </div>

          <div className="card-deck">
            <div className="card text-center border-0">
              <div className="text-center mt--50">
                <img
                  className="text-center rounded-circle border-4"
                  style={{ height: "100px", width: "100px" }}
                  src={tsazzad}
                  alt="icon"
                />
              </div>
              <div className="card-body">
                <p className="card-text text-justify text-center pb-2">
                  Very Nice and comfortable place to check in.Their service is
                  also good but it seems pricing is too high.Though I liked
                  their behaviour but they should upgrade their room facility.
                </p>
                <img
                  className="text-center"
                  // style={{ height: "75px" }}
                  src={star}
                  alt="icon"
                />
                <img
                  className="text-center"
                  // style={{ height: "75px" }}
                  src={star}
                  alt="icon"
                />
                <img
                  className="text-center"
                  // style={{ height: "75px" }}
                  src={star}
                  alt="icon"
                />
                <img
                  className="text-center"
                  // style={{ height: "75px" }}
                  src={star}
                  alt="icon"
                />
                <img
                  className="text-center"
                  // style={{ height: "75px" }}
                  src={star}
                  alt="icon"
                />
                <p className="card-text text-justify text-center mt-3 pb-1">
                  Sazzad Zaman Satu
                </p>
              </div>
            </div>
            <div className="card text-center border-0 mainBG">
              <div className="text-center mt--50">
                <img
                  className="text-center rounded-circle"
                  style={{ height: "120px", width: "120px" }}
                  src={tmirza}
                  alt="icon"
                />
              </div>
              <div className="card-body">
                <p className="card-text text-justify text-center pb-2">
                  The staff and service are awesome. Staff are so friendly.
                  Cleaning is to much pretty. Specially location is main town
                  that's why I like most than another hotel in Sylhet.
                </p>
                <img
                  className="text-center"
                  // style={{ height: "75px" }}
                  src={star}
                  alt="icon"
                />
                <img
                  className="text-center"
                  // style={{ height: "75px" }}
                  src={star}
                  alt="icon"
                />
                <img
                  className="text-center"
                  // style={{ height: "75px" }}
                  src={star}
                  alt="icon"
                />
                <img
                  className="text-center"
                  // style={{ height: "75px" }}
                  src={star}
                  alt="icon"
                />
                <img
                  className="text-center"
                  // style={{ height: "75px" }}
                  src={star}
                  alt="icon"
                />
                <p className="card-text text-justify text-center mt-3 pb-1">
                  Mirza Md Muktar
                </p>
              </div>
            </div>
            <div className="card text-center border-0">
              <div className="text-center mt--50">
                <img
                  className="text-center rounded-circle border-4"
                  style={{ height: "100px", width: "100px" }}
                  src={tnizam}
                  alt="icon"
                />
              </div>
              <div className="card-body">
                <p className="card-text text-justify text-center pb-2">
                  It's a apartment hotel. The apartments are usually 2 Bedroom
                  or 3 Bedroom ones plus living and dining area. Reasonable
                  rates. Good place to stay with family. Breakfast included - a
                  decent present menu.
                </p>
                <img
                  className="text-center"
                  // style={{ height: "75px" }}
                  src={star}
                  alt="icon"
                />
                <img
                  className="text-center"
                  // style={{ height: "75px" }}
                  src={star}
                  alt="icon"
                />
                <img
                  className="text-center"
                  // style={{ height: "75px" }}
                  src={star}
                  alt="icon"
                />
                <img
                  className="text-center"
                  // style={{ height: "75px" }}
                  src={star}
                  alt="icon"
                />
                <img
                  className="text-center"
                  // style={{ height: "75px" }}
                  src={star}
                  alt="icon"
                />
                <p className="card-text text-justify text-center mt-3 pb-1">
                  Nizam Ahmed
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="googleMap m-0 p-0">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d452.37820511303164!2d91.868075!3d24.897107!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa5a3f29417b4d4cb!2sRichmond%20Hotel%20Sylhet!5e0!3m2!1sen!2sbd!4v1648459609570!5m2!1sen!2sbd"
          width="100%"
          height="600"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="googlemap"
        ></iframe>
      </div>
    </div>
  );
};

export default Home;
