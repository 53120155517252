import { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import "./App.css";
import RoomsTarrif from "./pages/RoomsTarrif/RoomsTarrif";
import RoomDetails from "./pages/RoomDetails/RoomDetails";
import Facilities from "./pages/Facilities/Facilities";
import Reservation from "./pages/Reservation/Reservation";
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import Restaurant from "./pages/Restaurant/Restaurant";
import Gallery from "./pages/Gallery/Gallery";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  const [selected, setSelected] = useState(0);
  const [checkStatus, setCheckStatus] = useState(false);

  const handleClick = (idx) => {
    setSelected(idx);
    localStorage.setItem("SelectedOption", idx);
  };

  return (
    <Router>
      <NavBar handleClick={handleClick} setCheckStatus={setCheckStatus} />
      <div className="app">
        <ScrollToTop>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  checkStatus={checkStatus}
                  setCheckStatus={setCheckStatus}
                  handleClick={handleClick}
                />
              }
              exact
            />
            <Route
              path="/rooms-tarrif"
              element={
                <RoomsTarrif
                  checkStatus={checkStatus}
                  setCheckStatus={setCheckStatus}
                />
              }
              exact
            ></Route>
            <Route path="/room-details" element={<RoomDetails />} exact></Route>
            <Route path="/facilities" element={<Facilities />} exact></Route>
            <Route path="/reservation" element={<Reservation />} exact></Route>
            <Route path="/restaurant" element={<Restaurant />} exact></Route>
            <Route path="/gallery" element={<Gallery />} exact></Route>
          </Routes>
        </ScrollToTop>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
