import React, { useEffect } from "react";
import "./Facilities.css";
import axiosInstance from "../../services/axiosInstance";
import BreadCrumb from "./../../components/BreadCrumb/BreadCrumb";
import { useState } from "react";
import breadCrumbImageI from "./../../assets/images/facilitiesPage.png";

import CheckAvailBox from "./../../components/CheckAvailBox/CheckAvailBox";

import facilities from "./../../assets/images/facilities.png";
import clock from "./../../assets/icon/clock.svg";

import facilities1 from "./../../assets/icon/facilities1.svg";
import facilities2 from "./../../assets/icon/facilities2.svg";
import facilities3 from "./../../assets/icon/facilities3.svg";
import facilities4 from "./../../assets/icon/facilities4.svg";
import facilities5 from "./../../assets/icon/facilities5.svg";
import facilities6 from "./../../assets/icon/facilities6.svg";
import facilities7 from "./../../assets/icon/facilities7.svg";
import facilities8 from "./../../assets/icon/facilities8.svg";
import facilities9 from "./../../assets/icon/facilities9.svg";

const Facilities = () => {
  const [breadCrumbTextV, setbreadCrumbTextV] = useState("");
  const [breadCrumbImageV, setbreadCrumbImageV] = useState("");
  const [banners, setBanners] = useState();

  useEffect(() => {
    axiosInstance
      .get("/api/banner")
      .then((res) => {
        setBanners(res?.data?.banners);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setbreadCrumbTextV("Facilities & Services  ");

    {
      banners &&
        banners.length > 0 &&
        banners.map(
          (banner, index) =>
            banner.page === "facilities" &&
            banner.status === "active" &&
            setbreadCrumbImageV(banner.image)
        );
    }
  });

  return (
    <div>
      <BreadCrumb
        breadCrumbText={breadCrumbTextV}
        breadCrumbImage={breadCrumbImageV}
      />

      <div className="bgPattern mt--200">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt---100 mb---100 text-center">
              <h2 className="pb-3">Services We Offer </h2>
              <p className="pb-3">
                Ideally suited for holding multi-faceted events, either
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="roomSection mt--100">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex flex-column justify-content-center">
              <img
                className="d-block w-100"
                src={facilities}
                alt="facilities"
              />
            </div>

            <div className="col-md-6 d-flex flex-column justify-content-center">
              <div className="tpBorder p-5 m-3">
                <div className="row">
                  <div className="col-md-6 col-sm-12 d-flex align-items-center">
                    <div className="hbBord w-25 border border-dark float-left"></div>
                    <h4 className="float-left mt-2">
                      &nbsp;&nbsp;Eat &amp; Drinks
                    </h4>
                  </div>
                  <div className="col-md-6 d-flex align-items-center"></div>
                </div>
                <h2>Multi Cuisine Restaurant</h2>
                <p className="text-justify pb-3 pt-3">
                  Therefore, we offer a delicious breakfast buffet made from
                  fresh ingredients to guarantee the good taste and the best
                  start to the day! Join us for breakfast and enjoy an unlimited
                  selection of home-baked pastries, continental charcuterie,
                  vegetables, yoghurt, grills, eggs, juices and fruits, as well
                  as various hot drinks.
                </p>
                <h4>The restaurents opening hours are :</h4>
                <div className="float-left d-flex align-items-center mb-3">
                  <img
                    className="text-center mr-2 float-left"
                    style={{ height: "30px" }}
                    src={clock}
                    alt="clock icon"
                  />
                  <div className="float-left mr-3">
                    Saturday-Wednesday 10.00am-6pm
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 d-flex flex-column justify-content-center mt-5">
              <h3>
                Everything Right Here <br /> You Need It.{" "}
              </h3>
            </div>
            <div className="col-md-6 d-flex flex-column justify-content-center mt-5">
              <p className="text-justify pt-3">
                Therefore, we offer a delicious breakfast buffet made from fresh
                ingredients guarantee the good taste and the best start to the
                day! Join us for breakfast and enjoy an unlimited selection of
                home-baked pastries, continental
              </p>
            </div>
            <div className="col-md-12  mt-5">
              <div className="col-md-4 text-center float-left p-4">
                <img
                  className="text-center p-2"
                  style={{ height: "100px" }}
                  src={facilities1}
                  alt="facilities icon"
                />
                <h4 className="text-center p-2">Basement car parking </h4>
                <p className="text-center">
                  Therefore, we offer a delicious breakfast buffet made from
                  fresh ingredients to.
                </p>
              </div>
              <div className="col-md-4 text-center float-left p-4">
                <img
                  className="text-center p-2"
                  style={{ height: "100px" }}
                  src={facilities2}
                  alt="facilities icon"
                />
                <h4 className="text-center p-2">Welcome drink </h4>
                <p className="text-center">
                  Therefore, we offer a delicious breakfast buffet made from
                  fresh ingredients to.
                </p>
              </div>
              <div className="col-md-4 text-center float-left p-4">
                <img
                  className="text-center p-2"
                  style={{ height: "100px" }}
                  src={facilities3}
                  alt="facilities icon"
                />
                <h4 className="text-center p-2">Breakfast </h4>
                <p className="text-center">
                  Therefore, we offer a delicious breakfast buffet made from
                  fresh ingredients to.
                </p>
              </div>
              <div className="col-md-4 text-center float-left p-4">
                <img
                  className="text-center p-2"
                  style={{ height: "100px" }}
                  src={facilities4}
                  alt="facilities icon"
                />
                <h4 className="text-center p-2">24 hours room service </h4>
                <p className="text-center">
                  Therefore, we offer a delicious breakfast buffet made from
                  fresh ingredients to.
                </p>
              </div>
              <div className="col-md-4 text-center float-left p-4">
                <img
                  className="text-center p-2"
                  style={{ height: "100px" }}
                  src={facilities5}
                  alt="facilities icon"
                />
                <h4 className="text-center p-2">Newspaper </h4>
                <p className="text-center">
                  Therefore, we offer a delicious breakfast buffet made from
                  fresh ingredients to.
                </p>
              </div>
              <div className="col-md-4 text-center float-left p-4">
                <img
                  className="text-center p-2"
                  style={{ height: "100px" }}
                  src={facilities6}
                  alt="facilities icon"
                />
                <h4 className="text-center p-2">Laundry service </h4>
                <p className="text-center">
                  Therefore, we offer a delicious breakfast buffet made from
                  fresh ingredients to.
                </p>
              </div>
              <div className="col-md-4 text-center float-left p-4">
                <img
                  className="text-center p-2"
                  style={{ height: "100px" }}
                  src={facilities7}
                  alt="facilities icon"
                />
                <h4 className="text-center p-2">Tour packages </h4>
                <p className="text-center">
                  Therefore, we offer a delicious breakfast buffet made from
                  fresh ingredients to.
                </p>
              </div>
              <div className="col-md-4 text-center float-left p-4">
                <img
                  className="text-center p-2"
                  style={{ height: "100px" }}
                  src={facilities8}
                  alt="facilities icon"
                />
                <h4 className="text-center p-2">Travel desk information </h4>
                <p className="text-center">
                  Therefore, we offer a delicious breakfast buffet made from
                  fresh ingredients to.
                </p>
              </div>
              <div className="col-md-4 text-center float-left p-4">
                <img
                  className="text-center p-2"
                  style={{ height: "100px" }}
                  src={facilities9}
                  alt="facilities icon"
                />
                <h4 className="text-center p-2">Currency exchange </h4>
                <p className="text-center">
                  Therefore, we offer a delicious breakfast buffet made from
                  fresh ingredients to.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facilities;
