import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axiosInstance from "../../services/axiosInstance";
import "./RoomDetails.css";
import BreadCrumb from "./../../components/BreadCrumb/BreadCrumb";
import { useState } from "react";
import breadCrumbImageI from "./../../assets/images/roomPage.png";
import CheckAvailBox from "./../../components/CheckAvailBox/CheckAvailBox";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import roundBed from "./../../assets/icon/round-bed.svg";
import tick from "./../../assets/icon/tickSign.svg";
import roundWifi from "./../../assets/icon/round-wifi.svg";
import roundMonitor from "./../../assets/icon/round-monitor.svg";
import roundAc from "./../../assets/icon/round-ac.svg";
import roundClean from "./../../assets/icon/round-clean.svg";
import room1 from "./../../assets/images/room1.png";
import room2 from "./../../assets/images/room2.png";
import room3 from "./../../assets/images/room3.png";
import noRoomFound from "./../../assets/images/noRoomFound.png";
import icon2 from "./../../assets/icon/right-arrow.svg";

const RoomDetails = () => {
  const [breadCrumbTextV, setbreadCrumbTextV] = useState("");
  const [breadCrumbImageV, setbreadCrumbImageV] = useState("");

  const [roomId, setRoomId] = useState("");
  const [roomImages, setRoomImages] = useState([]);
  const [roomDetails, setRoomDetails] = useState([]);
  const [similarRooms, setSimilarRooms] = useState([]);
  const [complimentaryService, setComplimentaryService] = useState([]);
  const [roomFacilities, setRoomFacilities] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setbreadCrumbTextV("Rooms & Tarrif");
    setbreadCrumbImageV(breadCrumbImageI);
    setRoomId(location.state.id);
  }, [location.state]);

  useEffect(() => {
    if (roomId !== undefined) {
      axiosInstance.get(`api/room/${roomId}`).then((res) => {
        setRoomFacilities(
          Object.keys(res.data.room.category.roomFacilities).filter(
            (key) => res.data.room.category.roomFacilities[key] === true
          )
        );
        setComplimentaryService(
          Object.keys(res.data.room.category.complimentaryService).filter(
            (key) => res.data.room.category.complimentaryService[key] === true
          )
        );
        setRoomDetails(res.data.room);
        setSimilarRooms(res.data.similarRooms);
        //setAdult(res.data.room.capacity.adult);
        setRoomImages(res.data.room.images);
      });
    }
  }, [roomId]);

  console.log(complimentaryService);
  // const images = [9, 8, 7, 6, 5].map((number) => ({
  //   src: `https://placedog.net/${number}00/${number}00?id=${number}`,
  // }));
  const images = roomImages.map((image) => ({
    src: image,
  }));

  const goToReservation = () => {
    navigate("/reservation", {
      state: {
        adult_capacity: roomDetails.capacity.adult,
        child_capacity: roomDetails.capacity.child,
        discount: roomDetails.discount,
        roomId: roomId,
      },
    });
  };

  const viewSimilarRoomHandler = (
    id,
    adult_capacity,
    child_capacity,
    discount
  ) => {
    navigate("/room-details", {
      state: {
        id: id,
        child_capacity: child_capacity,
        adult_capacity: adult_capacity,
        discount: discount,
      },
    });
  };

  return (
    <div>
      <BreadCrumb
        breadCrumbText={breadCrumbTextV}
        breadCrumbImage={breadCrumbImageV}
      />

      <div className="bgPattern mt--200">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt---100 mb---100 text-center">
              <h2 className="pb-3">{roomDetails.name} </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="roomSection mt--100">
        <div className="container">
          <div className="row">
            <div className="col-md-12 d-flex flex-column justify-content-center">
              <Carousel images={images} style={{ height: 800 }} />

              <p className="text-justify pb-2 pt-4">
                {roomDetails.description}
              </p>

              {location.state.checkStatus === true ? (
                <div>
                  <button
                    onClick={goToReservation}
                    className="commonBtn1"
                    style={{ fontSize: "15px" }}
                  >
                    Book Now
                  </button>
                </div>
              ) : null}
            </div>
            <div className="col-md-12 pt-5">
              <h2 className="pb-3">Amenities </h2>
              <div className="col-md-6 p-3 transparentBG float-left">
                <ul className="m-0 p-0 list-unstyled">
                  {roomFacilities.map((item) => (
                    <li>
                      <div className="d-flex align-items-center mb-3">
                        <img
                          className="text-center mr-2 float-left"
                          style={{ height: "40px" }}
                          src={tick}
                          alt="ac icon"
                        />
                        <div className="float-left mr-3">
                          {(item === "hotAndColdWater" &&
                            "Hot and Cold Water") ||
                            (item === "diningFacility" && "Dining Facility") ||
                            (item === "fridge" && "Fridge") ||
                            (item === "tv" && "TV") ||
                            (item === "network" && "Network") ||
                            (item === "wifi" && "Wifi") ||
                            (item === "varietyOfBathroom" &&
                              "Variety of Bathroom") ||
                            (item === "roundTheClockStandBy" &&
                              "Round The Clock Stand By") ||
                            (item === "generator" && "Generator") ||
                            (item === "carParking" && "CarParking") ||
                            (item === "fireFightingEquipment" &&
                              "Fire Fighting Equipment") ||
                            (item === "ac" && "AC")}
                        </div>
                      </div>
                    </li>
                  ))}
                  {/* <li>
                    <div className="d-flex align-items-center mb-3">
                      <img
                        className="text-center mr-2 float-left"
                        style={{ height: "40px" }}
                        src={roundWifi}
                        alt="wifi icon"
                      />
                      <div className="float-left mr-3">24/7 wifi available</div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center mb-3">
                      <img
                        className="text-center mr-2 float-left"
                        style={{ height: "40px" }}
                        src={roundMonitor}
                        alt="monitor icon"
                      />
                      <div className="float-left mr-3">Televison</div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center mb-3">
                      <img
                        className="text-center mr-2 float-left"
                        style={{ height: "40px" }}
                        src={roundAc}
                        alt="ac icon"
                      />
                      <div className="float-left mr-3">Aircondition</div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex align-items-center mb-3">
                      <img
                        className="text-center mr-2 float-left"
                        style={{ height: "40px" }}
                        src={roundClean}
                        alt="ac icon"
                      />
                      <div className="float-left mr-3">24/7 Clean Service </div>
                    </div>
                  </li> */}
                </ul>
              </div>
              <div className="col-md-6 float-left">
                <h2 className="pb-3"> Complementary Service </h2>
                <div className="row">
                  {complimentaryService &&
                    complimentaryService.length > 0 &&
                    complimentaryService.map((item) => {
                      return (
                        <div className="col-md-6">
                          <div
                            className="transparentBG text-center mb-3 p-2"
                            style={{ fontSize: "15px" }}
                          >
                            {(item === "bottleOfWater" && "Bottle Of Water") ||
                              (item === "swimmingPool" && "Swimming Pool") ||
                              (item === "wifi" && "24/7 wifi available") ||
                              (item === "billiardBoard" && "Billiard Board") ||
                              (item === "tableTennis" && "Table Tennis") ||
                              (item === "zooVisit" && "Zoo") ||
                              (item === "gym" && "Gym") ||
                              (item === "breackfast" && "Breackfast") ||
                              (item === "expressCheckInAndCheckOut" &&
                                "Express CheckIn And CheckOut")}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="roomDCardBox">
        <div className="container">
          <h2 className="d-block pb-3">
            We Have Some Others <br /> Similar Rooms.
          </h2>

          {/* <div className="text-right mt-4 mb-4">
            <button className="commonBtn1" style={{ fontSize: "15px" }}>
              View All <img src={icon2} alt="" className="commonIcon1" />
            </button>
          </div> */}

          <div className="card-deck">
            {similarRooms.map((room) => (
              <div className="card text-center">
                <img
                  className="text-center"
                  style={{ height: "290px" }}
                  src={room.images[0]}
                  alt="icon"
                />
                <div class="overlay-img">
                  <div className="room-info">
                    <h1 className="room-name">{room.name}</h1>
                    <div className="room-description-wrapper">
                      <h6 style={{ marginBottom: "0" }}>
                        <del>{room.price}</del>
                      </h6>
                      <h5 style={{ marginBottom: "0" }}>
                        {" "}
                        {room.totalPrice} BDT
                      </h5>
                    </div>
                    <div className="book-now-button">
                      <button
                        onClick={() =>
                          viewSimilarRoomHandler(
                            room._id,
                            room.capacity.adult,
                            room.capacity.child,
                            room.discount
                          )
                        }
                        className="button"
                      >
                        View
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {similarRooms.length === 3 && (
              <div className="card text-center">
                <img
                  className="text-center"
                  // style={{ height: "75px" }}
                  src={noRoomFound}
                  alt="icon"
                />
              </div>
            )}
            {similarRooms.length === 2 && (
              <>
                <div className="card text-center">
                  <img
                    className="text-center"
                    // style={{ height: "75px" }}
                    src={noRoomFound}
                    alt="icon"
                  />
                </div>
                <div className="card text-center">
                  <img
                    className="text-center"
                    // style={{ height: "75px" }}
                    src={noRoomFound}
                    alt="icon"
                  />
                </div>
              </>
            )}
            {similarRooms.length === 1 && (
              <>
                <div className="card text-center">
                  <img
                    className="text-center"
                    // style={{ height: "75px" }}
                    src={noRoomFound}
                    alt="icon"
                  />
                </div>
                <div className="card text-center">
                  <img
                    className="text-center"
                    // style={{ height: "75px" }}
                    src={noRoomFound}
                    alt="icon"
                  />
                </div>
                <div className="card text-center">
                  <img
                    className="text-center"
                    // style={{ height: "75px" }}
                    src={noRoomFound}
                    alt="icon"
                  />
                </div>
              </>
            )}
            {similarRooms.length === 0 && (
              <>
                <div className="card text-center">
                  <img
                    className="text-center"
                    // style={{ height: "75px" }}
                    src={noRoomFound}
                    alt="icon"
                  />
                </div>
                <div className="card text-center">
                  <img
                    className="text-center"
                    // style={{ height: "75px" }}
                    src={noRoomFound}
                    alt="icon"
                  />
                </div>
                <div className="card text-center">
                  <img
                    className="text-center"
                    // style={{ height: "75px" }}
                    src={noRoomFound}
                    alt="icon"
                  />
                </div>
                <div className="card text-center">
                  <img
                    className="text-center"
                    // style={{ height: "75px" }}
                    src={noRoomFound}
                    alt="icon"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomDetails;
