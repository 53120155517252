import React, { useEffect } from "react";
import "./RoomsTarrif.css";
import axiosInstance from "../../services/axiosInstance";
import BreadCrumb from "./../../components/BreadCrumb/BreadCrumb";
import { useState } from "react";
import breadCrumbImageI from "./../../assets/images/roomPage.png";
import { useLocation } from "react-router-dom";
import CheckAvailBox from "./../../components/CheckAvailBox/CheckAvailBox";
import Carousel from "../../components/carousel/Carousel";
import mug from "./../../assets/icon/mug.svg";
import wifi from "./../../assets/icon/wifi.svg";
import icon6 from "./../../assets/icon/ac-service.svg";
import adultIcon from "./../../assets/icon/adult.svg";
import children from "./../../assets/icon/children.svg";
import { useNavigate } from "react-router-dom";

const RoomsTarrif = ({ checkStatus, setCheckStatus }) => {
  const navigate = useNavigate();

  const [banners, setBanners] = useState();
  const [checkStatusFromRoom, setCheckStatusFromRoom] = useState(false);
  const arrivalDate = localStorage.getItem("checkIn");
  const departureDate = localStorage.getItem("checkOut");
  const adult = localStorage.getItem("guestNum");

  const goToRoomDetails = (id, adult_capacity, child_capacity, discount) => {
    navigate("/room-details", {
      state: {
        id: id,
        checkStatus: checkStatus,
        child_capacity: child_capacity,
        adult_capacity: adult_capacity,
        discount: discount,
      },
    });
  };

  const goToReservation = (id, adult_capacity, child_capacity) => {
    let obj = {
      child_capacity: child_capacity,
      adult_capacity: adult_capacity,
      roomId: id,
    };

    navigate("/reservation", { state: obj });
  };

  const [breadCrumbTextV, setbreadCrumbTextV] = useState("");
  const [breadCrumbImageV, setbreadCrumbImageV] = useState("");

  const [rooms, setRooms] = useState({});
  const { state } = useLocation();

  useEffect(() => {
    axiosInstance
      .get("/api/banner")
      .then((res) => {
        setBanners(res?.data?.banners);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setbreadCrumbTextV("Rooms & Tarrif");
    {
      banners &&
        banners.length > 0 &&
        banners.map(
          (banner, index) =>
            banner.page === "rooms&suites" &&
            banner.status === "active" &&
            setbreadCrumbImageV(banner.image)
        );
    }
  });

  useEffect(() => {
    if (checkStatus === true || checkStatusFromRoom === true) {
      setRooms("");
      axiosInstance
        .get(
          `/api/room/available-rooms?arrivalDate=${arrivalDate}&departureDate=${departureDate}&adult=${adult}`
        )
        .then((res) => {
          setRooms(res.data.rooms);
        });
    } else {
      axiosInstance.get("/api/room").then((res) => {
        setRooms(res.data.rooms);
      });
    }
  }, [checkStatus, checkStatusFromRoom]);

  return (
    <div>
      <BreadCrumb
        breadCrumbText={breadCrumbTextV}
        breadCrumbImage={breadCrumbImageV}
      />

      <CheckAvailBox
        checkStatus={checkStatus}
        setCheckStatus={setCheckStatus}
        checkStatusFromRoom={checkStatusFromRoom}
        setCheckStatusFromRoom={setCheckStatusFromRoom}
        page="room&terrif"
      />

      <div className="bgPattern mt--100">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt---100 mb---100 text-center">
              <h2 className="pb-3">Discover Rooms &amp; Suite </h2>
              <p className="pb-3">
                At richmond we understand the different expectations of
                visitors. We feel elated when you come back for the second time!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="roomTerrif">
        <div className="roomSection mt--100">
          {rooms &&
            rooms.length > 0 &&
            rooms?.map((room, index) => {
              return (
                <div className="container" key={room._id}>
                  <div
                    className={index % 2 ? "room row" : "row"}
                    style={{ marginBottom: "90px" }}
                  >
                    <div className="col-md-6">
                      <div className="containerSlide">
                        <Carousel slides={room.images} controls indicators />
                      </div>
                    </div>
                    <div className="col-md-6 d-flex flex-column justify-content-center">
                      <div className="row">
                        <div className="col-md-6">
                          <button
                            className="commonBtn1 text-uppercase"
                            style={{ fontSize: "25px" }}
                          >
                            {room.name}
                          </button>
                        </div>
                        <div className="col-md-6"></div>
                      </div>

                      <p className="text-justify pb-3 pt-3">
                        {room.description}
                      </p>
                      <div className="float-left d-flex align-items-center mb-3">
                        <img
                          className="text-center mr-2 float-left"
                          style={{ height: "40px" }}
                          src={wifi}
                          alt="icon"
                        />

                        {room?.category.complimentaryService.wifi === true ? (
                          <div className="float-left mr-3">
                            24/7 wifi available
                          </div>
                        ) : (
                          <div className="float-left mr-3">
                            Wifi not available
                          </div>
                        )}

                        <img
                          className="text-center mr-2 float-left"
                          style={{ height: "30px" }}
                          src={icon6}
                          alt="icon"
                        />

                        {room?.category.roomFacilities.ac === true ? (
                          <div className="float-left mr-3">AC</div>
                        ) : (
                          <div className="float-left mr-3">Non-AC</div>
                        )}

                        <img
                          className="text-center float-left"
                          style={{ height: "40px" }}
                          src={mug}
                          alt="icon"
                        />

                        {room?.category.complimentaryService.breackfast ===
                        true ? (
                          <div className="float-left mr-3">
                            Breakfast Included
                          </div>
                        ) : (
                          <div className="float-left mr-3">
                            Breakfast not Included
                          </div>
                        )}
                      </div>

                      <div className="float-left d-flex align-items-center mb-3">
                        <img
                          className="text-center mr-2 float-left"
                          style={{ height: "40px" }}
                          src={adultIcon}
                          alt="adult icon"
                        />
                        <div className="float-left mr-3">
                          {room.capacity.adult} ADULTS
                        </div>
                        <img
                          className="text-center mr-2 float-left"
                          style={{ height: "40px" }}
                          src={children}
                          alt="children icon"
                        />
                        <div className="float-left mr-3">
                          {room.capacity.child} CHILDREN
                        </div>
                      </div>

                      <div>
                        <h4 className="float-left">ROOM RATE :&nbsp;</h4>
                        {room.discount !== 0 && (
                          <h4 className="float-left text-muted">
                            <s>{room.price}</s>
                          </h4>
                        )}

                        <p className="float-left mt-2">&nbsp;&nbsp;</p>
                        <h4 className="float-left">{room.totalPrice} BDT</h4>
                        <p className="float-left mt-1">/Night</p>
                      </div>
                      <div>
                        {checkStatus === true ? (
                          <>
                            <button
                              onClick={() =>
                                goToReservation(
                                  room._id,
                                  room.capacity.adult,
                                  room.capacity.child
                                )
                              }
                              className="commonBtn1"
                              style={{ fontSize: "15px" }}
                            >
                              Book Now
                            </button>
                            <div className="divider"></div>
                          </>
                        ) : null}
                        <button
                          className="commonBtn2 text-dark"
                          style={{ fontSize: "15px" }}
                          onClick={() =>
                            goToRoomDetails(
                              room._id,
                              room.capacity.adult,
                              room.capacity.child,
                              room.discount
                            )
                          }
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      {/* 
      <div className="roomSection">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex flex-column justify-content-center">
              <div className="row">
                <div className="col-md-6">
                  <button
                    className="commonBtn1 text-uppercase"
                    style={{ fontSize: "25px" }}
                  >
                    Deluxe Couple
                  </button>
                </div>
                <div className="col-md-6"></div>
              </div>

              <p className="text-justify pb-3 pt-3">
                The Classic Rooms offer spacious high ceiling rooms with
                chandeliers and ultimate peace and tranquility with bay windows
                looking out on to a peaceful conservation area. All designed
                with indulging comfort in mind and measuring 18m2 of stylish
                living space appointed to the highest standards.
              </p>
              <div className="float-left d-flex align-items-center mb-3">
                <img
                  className="text-center mr-2 float-left"
                  style={{ height: "40px" }}
                  src={wifi}
                  alt="icon"
                />
                <div className="float-left mr-3">24/7 WiFi Available</div>
                <img
                  className="text-center mr-2 float-left"
                  style={{ height: "30px" }}
                  src={icon6}
                  alt="icon"
                />
                <div className="float-left mr-3">AC</div>
                <img
                  className="text-center float-left"
                  style={{ height: "40px" }}
                  src={mug}
                  alt="icon"
                />
                <div className="float-left mr-3">Breakfast Included</div>
              </div>

              <div className="float-left d-flex align-items-center mb-3">
                <img
                  className="text-center mr-2 float-left"
                  style={{ height: "40px" }}
                  src={adult}
                  alt="adult icon"
                />
                <div className="float-left mr-3">02 ADULTS</div>
                <img
                  className="text-center mr-2 float-left"
                  style={{ height: "40px" }}
                  src={children}
                  alt="children icon"
                />
                <div className="float-left mr-3">02 CHILDREN</div>
              </div>

              <div>
                <h4 className="float-left">ROOM RATE :&nbsp;</h4>
                <h4 className="float-left text-muted">
                  <s>3795.00</s>
                </h4>
                <p className="float-left mt-2">&nbsp;&nbsp;</p>
                <h4 className="float-left">2657.00 BDT</h4>
                <p className="float-left mt-1">/Night</p>
              </div>
              <div>
                <button className="commonBtn1" style={{ fontSize: "15px" }}>
                  Book Now
                </button>
                <div className="divider" />
                <button
                  className="commonBtn2 text-dark"
                  onClick={goToRoomDeails}
                >
                  View Details
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="containerSlide">
                <Carousel slides={slides} controls indicators />
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="containerSlide">
        <Carousel slides={slides} controls indicators width={1200} />
      </div> */}
    </div>
  );
};

export default RoomsTarrif;
