import React, { useEffect, useState } from "react";
import axiosInstance from "../../services/axiosInstance";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "./Reservation.css";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import breadCrumbImageI from "./../../assets/images/reservationPage.png";
import CheckAvailBox from "../../components/CheckAvailBox/CheckAvailBox";
import "./toast.css";
import "react-toastify/dist/ReactToastify.css";

const Reservation = () => {
  const [breadCrumbTextV, setbreadCrumbTextV] = useState("");
  const [breadCrumbImageV, setbreadCrumbImageV] = useState("");

  const [name, setName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [address, setAddress] = useState("");
  const [nationality, setNationality] = useState("");
  const [age, setAge] = useState("");
  const [profession, setProfession] = useState("");
  const [passport, setPassport] = useState("");
  const [NID, setNID] = useState("");
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [adults, setAdults] = useState("");
  const [children, setChildren] = useState("");
  const [note, setNote] = useState("");
  const arrivalDate = localStorage.getItem("checkIn");
  const departureDate = localStorage.getItem("checkOut");
  const location = useLocation();

  console.log(location.state);

  useEffect(() => {
    setbreadCrumbTextV("Rooms Reservation  ");
    setbreadCrumbImageV(breadCrumbImageI);
    setAdults(location.state.adult_capacity);
    setChildren(location.state.child_capacity);
  }, [location.state]);

  const handleReservationForm = (e) => {
    e.preventDefault();
    axiosInstance
      .post("/api/customer/request-reservation", {
        firstName: name,
        mobile: mobileNo,
        address,
        age,
        profession,
        nationality,
        NID,
        passportId: passport,
        children,
        adults,
        arrivalDate: handleArrivalDateChange(arrivalDate),
        departureDate: handleDepartureDateChange(departureDate),
        notes: note,
        room: location.state.roomId,
        reservation_request: true,
      })
      .then((res) => {
        if (res.status === 201) {
          resetFormHandler();
          toast.success("Reservation Requested", {
            position: "top-right",
            top: "6em",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        toast.error("Something went wrong", {
          position: "top-right",
          top: "6em",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleArrivalDateChange = (arrivalDate) => {
    let date = arrivalDate;
    let dateToString = date.toString();
    let modifiedDateString = dateToString.substring(0, 10) + "Z" + "00:00:00";
    return modifiedDateString;
  };

  const handleDepartureDateChange = (departureDate) => {
    let date = departureDate;
    let dateToString = date.toString();
    let modifiedDateString = dateToString.substring(0, 10) + "Z" + "00:00:00";
    return modifiedDateString;
  };

  const resetFormHandler = () => {
    setName("");
    setMobileNo("");
    setAddress("");
    setNationality("");
    setAge("");
    setProfession("");
    setPassport("");
    setNID("");
    setAdults("");
    setNote("");
  };

  return (
    <div>
      <BreadCrumb
        breadCrumbText={breadCrumbTextV}
        breadCrumbImage={breadCrumbImageV}
      />

      <div className="bgPattern mt--200">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt---100 mb---100 text-center">
              <h2 className="pb-3">Reserve Your Room </h2>
              <p className="pb-3">ENTER YOUR DETAILS</p>
            </div>
          </div>
        </div>
      </div>

      <div className="roomSection mt--100">
        <div className="container">
          <form onSubmit={handleReservationForm}>
            <div className="form-row">
              <div className="form-group col-md-6 pr-4 pl-4">
                <label htmlFor="inputName">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputName"
                  placeholder="Your Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group col-md-6 pr-4 pl-4">
                <label htmlFor="inputMNumber">Mobile Number (+880)</label>

                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text p-0 pl-2 pr-2">+880</div>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    id="inputMNumber"
                    placeholder="1711223344"
                    value={mobileNo}
                    onChange={(e) => setMobileNo(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group col-md-12 pr-4 pl-4">
                <label htmlFor="inputAddress">Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputAddress"
                  placeholder="1234 Main St"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-4 pr-4 pl-4">
                <label htmlFor="inputCity">Nationality</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputCity"
                  value={nationality}
                  onChange={(e) => setNationality(e.target.value)}
                />
              </div>
              <div className="form-group col-md-4 pr-4 pl-4">
                <label htmlFor="inputCity">Age</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputCity"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                />
              </div>
              <div className="form-group col-md-4 pr-4 pl-4">
                <label htmlFor="inputState">Profession</label>
                <input
                  id="inputState"
                  className="form-control"
                  value={profession}
                  onChange={(e) => setProfession(e.target.value)}
                />
              </div>
              <div className="form-group col-md-6 pr-4 pl-4">
                <label htmlFor="inputZip">Passport Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputZip"
                  value={passport}
                  onChange={(e) => setPassport(e.target.value)}
                />
              </div>
              <div className="form-group col-md-6 pr-4 pl-4">
                <label htmlFor="inputZip">NID</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputZip"
                  value={NID}
                  onChange={(e) => setNID(e.target.value)}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6 pr-4 pl-4">
                <label htmlFor="inputCity">Check In</label>
                <input
                  type="date"
                  className="form-control"
                  id="inputCity"
                  value={arrivalDate}
                  onChange={(e) => setCheckIn(e.target.value)}
                  disabled
                />
              </div>
              <div className="form-group col-md-6 pr-4 pl-4">
                <label htmlFor="inputState">Check Out</label>
                <input
                  type="date"
                  id="inputState"
                  className="form-control"
                  value={departureDate}
                  onChange={(e) => setCheckOut(e.target.value)}
                  disabled
                />
              </div>
              <div className="form-group col-md-6 pr-4 pl-4">
                <label htmlFor="inputZip">Number of Adults</label>
                <input
                  type="number"
                  className="form-control"
                  id="inputZip"
                  placeholder="01"
                  value={adults}
                  onChange={(e) => setAdults(e.target.value)}
                  disabled
                />
              </div>
              <div className="form-group col-md-6 pr-4 pl-4">
                <label htmlFor="inputZip">Number of Children</label>
                <input
                  type="number"
                  className="form-control"
                  id="inputZip"
                  placeholder="01"
                  value={children}
                  onChange={(e) => setChildren(e.target.value)}
                  disabled
                />
              </div>
              <div className="form-group col-md-12 pr-4 pl-4">
                <label htmlFor="exampleFormControlTextarea1">
                  Special Note
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder="I will be late about one hour....."
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-12 pr-4 pl-4">
                <button
                  className="commonBtn1 pr-5 pl-5 pt-2 pb-2"
                  style={{ fontSize: "15px" }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Reservation;
